<template>
  <div class="home-monitor" style="margin: 0 24px;">
    <part-title title="家庭监测设备运行日志"></part-title>
    <div class="search">
      <template>
        <span class="el-span">ICM设备序列号：</span>
        <el-input
          size="small"
          class="el-input"
          v-model="searchData.deviceSn"
          placeholder="请输入ICM序列号">
        </el-input>
        <span class="el-span">家庭监测设备序列号：</span>
        <el-input
          size="small"
          class="el-input"
          v-model="searchData.gatewaySn"
          placeholder="请输入网关序列号">
        </el-input>
        <span class="el-span">同步状态：</span>
        <el-select class="el-select" size="small" v-model="searchData.uploadStatus" @change="onUploadStatusChange" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.code"
            :label="item.name"
            :value="item.code">
          </el-option>
        </el-select>
        <el-button
          type="primary"
          size="small"
          @click="onSearch"
          class="search-btn">
          查询
        </el-button>
      </template>
    </div>
    <el-table
      :data="tableData">
      <el-table-column
        align="center"
        fixed
        label="序号"
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        align="center"
        prop="uploadMethod"
        label="上传通道"
        :formatter="mapUploadMethod">
      </el-table-column>
      <el-table-column
        align="center"
        prop="deviceSn"
        label="ICM序列号">
      </el-table-column>
      <el-table-column
        align="center"
        prop="gatewaySn"
        label="家庭监测设备序列号"
        :formatter="setGatewaySnDefault">
      </el-table-column>
      <el-table-column
        align="center"
        prop="startTime"
        :formatter="formatStartTime"
        label="ICM传出时间（云端）">
      </el-table-column>
      <el-table-column
        align="center"
        prop="deviceLocalTime"
        :formatter="formatDeviceLocalTime"
        label="ICM传出时间（本地）">
      </el-table-column>
      <el-table-column
        align="center"
        prop="endTime"
        :formatter="formatEndTime"
        label="云端接收完成时间">
      </el-table-column>
      <el-table-column
        align="center"
        label="持续时间"
        :formatter="calculateUploadTime">
      </el-table-column>
      <el-table-column
        align="center"
        prop="status"
        label="同步状态"
        :formatter="formatStatus">
      </el-table-column>
      <el-table-column
        align="center"
        prop="eventNumber"
        label="ICM识别的事件数"
        :formatter="summarizeUploadEvents">
      </el-table-column>
    </el-table>
    <Page
      ref="pageRef"
      @handleSizeChange="sizeChange"
      @handleCurrentChange="pageChange"
      :page="currentPage"
      :page-size="searchData.pageSize"
      :total="totalPage"
    ></Page>
    <div style="height: 80px"></div>
  </div>
</template>

<script>
import { getIcmSampleRecordLogs } from '@/serve/module/ICMDeviceManage'
import PartTitle from '@/components/Part'
import Page from '@/components/Page'
const moment = require('moment')
export default {
  components: {
    PartTitle,
    Page
  },
  data () {
    return {
      moment: moment,
      searchData: { // 搜索数据
        pageNo: 1, // 当前页
        pageSize: 20, // 每页条数
        deviceSn: null,
        gatewaySn: null,
        uploadStatus: 0
      },
      totalPage: 1,
      tableData: [],
      isVisibleDetailModel: false,

      modelId: null,
      statusText: '-',
      currentSn: '',
      currentPage: 1,

      options: [{
        code: 0,
        name: '全部'
      }, {
        code: 1,
        name: '完全成功'
      }, {
        code: 2,
        name: '部分成功'
      }, {
        code: 3,
        name: '失败'
      }]
    }
  },
  watch: {
    'searchData.gatewaySn': 'updatePageNo',
    'searchData.deviceSn': 'updatePageNo'
  },
  mounted () {
    this.getList()
  },
  filters: {
    // 当标题字数超出时，超出部分显示’...‘。此处限制超出8位即触发隐藏效果
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 8) {
        return value.slice(0, 8) + '...'
      }
      return value
    }
  },
  methods: {
    onSearch () {
      this.getList()
    },
    async getList () {
      try {
        const res = await getIcmSampleRecordLogs(this.searchData)
        console.log('获取列表:', res)
        this.tableData = res.data.data || []
        this.totalPage = res.data.total
        this.pageNo = res.data.pageNo
        this.pageSize = res.data.pageSize
        this.currentPage = this.searchData.pageNo
        console.log('currentPage is : ', this.currentPage)
        // 设置当前显示页面
        // const myPage = this.$refs.pageRef
        // myPage.updatePage(res.data.pageNo)
      } catch (err) {
        this.$message({
          message: err.message,
          type: 'error'
        })
      }
    },
    sizeChange (val) {
      this.searchData.pageSize = val
      this.getList()
    },
    pageChange (val) {
      this.searchData.pageNo = val
      this.getList()
    },
    formatStartTime (row) {
      return moment.unix(row.startTime / 1000).format('Y-MM-DD HH:mm:ss')
    },
    formatDeviceLocalTime (row) {
      // 检查 deviceLocalTime 是否为空
      if (!row.deviceLocalTime) {
        return '--'
      }
      // 将毫秒时间戳转换为秒，并格式化为 YYYY-MM-DD HH:mm:ss
      return moment.unix(row.deviceLocalTime / 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    formatEndTime (row) {
      return moment.unix(row.endTime / 1000).format('Y-MM-DD HH:mm:ss')
    },
    formatStatus (row) {
      const statusMap = {
        1: '完全成功',
        2: '部分成功',
        3: '失败',
        99: '上传中'
      }
      return statusMap[row.status] || '未知状态'
    },
    mapUploadMethod (row) {
      const methodMap = {
        0: '小程序',
        1: '家庭监护'
      }
      return methodMap[row.uploadMethod] || '未知通道'
    },
    calculateUploadTime (row) {
      const timeLength = row.endTime - row.startTime
      // 将毫秒转换为秒
      const seconds = Math.floor(timeLength / 1000)

      // 计算时、分、秒
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const secondsRemainder = seconds % 60

      // 用零填充两位数表示，确保格式正确
      const formattedHours = String(hours).padStart(2, '0')
      const formattedMinutes = String(minutes).padStart(2, '0')
      const formattedSeconds = String(secondsRemainder).padStart(2, '0')

      // 组合成 HH:mm:ss 格式的字符串
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    },
    setGatewaySnDefault (row) {
      return row.gatewaySn === '' ? '--' : row.gatewaySn
    },
    summarizeUploadEvents (row) {
      return row.received.toString() + '/' + row.total.toString()
    },
    async onUploadStatusChange () {
      // 重置分页至第一页
      this.searchData.pageNo = 1
    },
    updatePageNo (newVal, oldVal) {
      if (newVal !== oldVal) {
        // 重置分页至第一页
        this.searchData.pageNo = 1
      }
    }
  }
}
</script>

<style scoped lang="scss">
.home-monitor {
  .search {
    margin-bottom: 12px;
    height: 32px;
    line-height: 32px;
    .el-input,.el-select {
      width: 150px;
      margin-right: 24px;
    }
    .el-span {
      font-weight: 400;
      font-size: 14px;
    }
    // .search-btn {
    //   font-size: 14px;
    // }
  }
  .add-new {
    margin-bottom: 12px;
  }
}
</style>
